.message .ant-message-notice-content {
  font-size: 18px;
  font-weight: bold;
  max-width: 100%;
  width: auto;
  min-height: 56px;
  height: auto;
  text-align: start;
  color: var(--basic-100);
  display: flex;
  align-items: center;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .message .ant-message-notice-content {
    width: auto;
    max-width: 704px;
    min-width: 308px;
  }
}

@media screen and (max-width: 767px) {
  .message .ant-message-notice-content {
    min-width: 308px;
    max-width: 100%;
    width: fit-content;
  }
}

.message .ant-message-custom-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message .ant-message-custom-content > svg {
  width: 25px;
  height: 25px;
  align-self: flex-start;
  margin-top: 3px;
}

.message .ant-message-custom-content > span {
  flex: 1;
}

.message .ant-message-custom-content span {
  word-break: break-all;
}

.message_success .ant-message-notice-content {
  background-color: var(--success-600);
  box-shadow: 0 5px 15px 0 rgba(0, 179, 131, 0.5);
}

.message_error .ant-message-notice-content {
  background-color: var(--danger-500);
  box-shadow: 0 5px 15px 0 rgba(246, 78, 95, 0.5);
}

.message_warning .ant-message-notice-content {
  background-color: var(--warning-500);
  box-shadow: 0 5px 15px 0 rgba(254, 159, 67, 0.5);
}

.message svg {
  margin-right: 13px;
}
