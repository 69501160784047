@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 99px;
}
::-webkit-scrollbar-thumb {
    border-radius: 99px;
     background-color: #c1c1c1;
 }

body {
    margin: 0;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-width: none;
    color: var(--basic-800);
    background-color: var(--basic-100) !important;
    scroll-behavior: smooth;
    line-height: 1.5;
}

body::-webkit-scrollbar {
    display: none;
}

.ant-scrolling-effect {
    width: 100% !important;
}

* {
    box-sizing: border-box;
}

*,
*:focus,
*:hover {
    outline: none;
}

a {
    list-style: none;
    color: var(--basic-800);
}

a:hover {
    color: var(--primary-500);
}

a:focus {
    color: var(--primary-600);
}

a:active {
    color: var(--primary-700);
}

#root {
    height: 100%;
}

body h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin-bottom: 0;
    color: var(--basic-800);
}

body h1 {
    font-size: 36px;
}

body h2 {
    font-size: 32px;
}

body h3 {
    font-size: 30px;
}

body h4 {
    font-size: 24px;
}

body h5 {
    font-size: 22px;
}

body h6 {
    font-size: 18px;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.mt-4 {
    margin-top: 4px;
}
.mt-8 {
    margin-top: 8px;
}
.mt-16 {
    margin-top: 16px;
}
.ml-4 {
    margin-left: 4px;
}
.ml-8 {
    margin-left: 8px;
}
.mb-8 {
    margin-bottom: 8px;
}

.pt-32 {
    padding-top: 32px;
}

:root {
    --basic-255-06: rgba(255, 255, 255, 0.6);

    --sheeta-disabled: #e0e0e0;
    --sheeta--dark: #000000;
    --sheeta--primary-100: #d1e9ff;
    --sheeta--primary-200: #b2ddff;
    --sheeta--primary-300: #84caff;
    --sheeta--primary-400: #53b1fd;
    --sheeta--primary-500: #2e90fa;
    --sheeta--primary-600: #1570ef;
    --sheeta--primary-700: #175cd3;
    --sheeta--primary-800: #1849a9;
    --sheeta--basic-100: #ffffff;
    --sheeta--basic-200: #f7f9fc;
    --sheeta--basic-300: #edf1f7;
    --sheeta--basic-400: #e4e9f2;
    --sheeta--basic-500: #e2e6ee;
    --sheeta--basic-600: #7e8299;
    --sheeta--basic-700: #2e3a59;
    --sheeta--basic-800: #222b45;
    --sheeta--basic-900: #192038;
    --sheeta--basic-1000: #151a30;
    --sheeta--basic-1100: #181c32;
    --sheeta--danger-100: #fee2e4;
    --sheeta--danger-200: #fcccbc;
    --sheeta--danger-300: #f8a999;
    --sheeta--danger-400: #fd97a1;
    --sheeta--danger-500: #f64e5f;
    --sheeta--danger-600: #db3442;
    --sheeta--danger-700: #b7243c;
    --success-100: #d4ffee;
    --success-500: #00d59c;
    --success-600: #00b383;
    --warning-100: #fff4dd;
    --warning-200: #fee4b3;
    --warning-300: #fed08e;
    --warning-400: #ffcb7e;
    --warning-500: #ffa800;
    --warning-600: #da7b30;
    --warning-700: #b86e00;

    --primary-100: #fff7ed;
    --primary-500: #ff9923;
    --primary-600: #fb8d21;
    --primary-700: #f47d1f;
    --basic-100: #ffffff;
    --basic-200: #f3f5f8;
    --basic-300: #f3f5f8;
    --basic-400: #bfc7df;
    --basic-600: #103178;
    --basic-800: #103178;
    --border-color: #e6e9f2;
    --disabled: #bfc7df;
    --placeholder: #bfc7df;
    --black: #000;
    --pdf-text: #4c4c4c;
    --pdf-bg: #e5e5e5;
    --menu-expand-bg: rgba(16, 49, 120, 0.08);

    --colorBorder: #e2e6ee;
    --colorInfo: #103178;
    --colorPrimary: #103178;
    --colorLink: #3366ff;
    --colorTextPlaceholder: #7e8299;
    --controlItemBgActive: #e4eaff;
}
