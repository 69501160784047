.ant-checkbox-inner {
  border-radius: 2px;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 1px solid var(--basic-100);
  border-top: 0;
  border-left: 0;
  transform: rotate(40deg) scale(1) translate(-60%, -60%);
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--basic-600);
}
