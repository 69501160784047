@import './button.less';
@import './checkbox.less';
@import './collapse.less';
@import './dropdown.less';
@import './masthead.less';
@import './message.less';
@import './spin.less';
@import './select.less';
@import './tag.less';
@import './menu.less';
@import './input.less';
@import './dropdown.less';

.ant-layout {
  background-color: var(--basic-100);
}
@primary-200: #ffcc86;@primary-100: #fff7ed;@primary-800: #ed6e1d;@primary-700: #f47d1f;@primary-300: #ffb757;@primary-900: #e3551b;@basic-100: #ffffff;@primary-500: #ff9923;@primary-400: #ffa737;@primary-600: #fb8d21;@basic-200: #f3f5f8;@basic-300: #f3f5f8;@basic-400: #bfc7df;@basic-500: #4d65a6;@basic-600: #103178;@basic-700: #103178;@basic-800: #103178;@basic-900: #012162;@danger-100: #ffe8e6;@danger-200: #ffc8b9;@danger-300: #ffa38c;@danger-500: #ff2c13;@danger-400: #ff7c5d;@danger-700: #fe1706;@danger-800: #f00000;@danger-600: #ff230e;@warning-100: #fff7ed;@warning-200: #ffcc86;@danger-900: #d90000;@warning-300: #ffb757;@warning-400: #ffa737;@warning-500: #ff9923;@warning-600: #fb8d21;@warning-700: #f47d1f;@warning-800: #ed6e1d;@success-100: #e5f5ec;@warning-900: #e3551b;@success-400: #6dc793;@success-300: #99d6b1;@success-200: #c1e6cf;@success-600: #1baf66;@success-500: #1baf66;@success-700: #048e4f;@success-800: #007d44;@success-900: #005d2f;@disabled: #bfc7df;@border-color: #e6e9f2;