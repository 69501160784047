.ant-input::placeholder,
input::placeholder,
textarea::placeholder {
  color: var(--placeholder);
}
.ant-input, input, textarea,
.ant-input-affix-wrapper {
  color: var(--basice-600);
}
.ant-input:hover,
input:hover,
textarea:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper-focused:not(.ant-select-disabled),
.ant-input:focus, .ant-input-focused {
  border-color: var(--basice-600);
}

.ant-input-affix-wrapper {
  input {
    border-radius: initial;
  }
}
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: var(--basice-500) !important;
  box-shadow: 0 0 0 2px rgba(50, 102, 255, 0.2) !important;
}

textarea.ant-input:focus, .ant-input-focused {
  border-color: var(--basice-500) !important;
  box-shadow: 0 0 0 2px rgba(50, 102, 255, 0.2) !important;
}