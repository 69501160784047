.ant-select-item,
.ant-select-selection-item {
  color: var(--basic-600);
}
.ant-select-selection-placeholder {
  color: var(--basic-400);
}

.ant-select-item-option {
  padding: 6px 12px;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--basic-200);
  color: var(--basic-600);
}
.ant-select-dropdown .ant-select-item-option-state {
  width: initial !important;
  height: initial !important;
}

.ant-select-dropdown .ant-select-item {
  color: var(--basic-600);
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--basic-200);
  color: var(--basic-600);
}