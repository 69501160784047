.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: flex;
  margin-left: 0;
  margin-right: 8px;
}

.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: flex;
  margin-right: 0;
  margin-left: 8px;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box,
.ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0;
}