.masthead-popover {
  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      .ant-popover-title {
        padding: 0;
      }
    }
  }
}
