.icon-btn-type-primary.ant-btn-text[disabled],
.icon-btn-type-primary.ant-btn-text[disabled]:focus,
.icon-btn-type-primary.ant-btn-text[disabled]:active,
.icon-btn-type-primary .ant-btn-text[disabled]:hover,
.icon-btn-type-primary-basic.ant-btn-text[disabled],
.icon-btn-type-primary-basic.ant-btn-text[disabled]:focus,
.icon-btn-type-primary-basic.ant-btn-text[disabled]:active,
.icon-btn-type-primary-basic.ant-btn-text[disabled]:hover,
.icon-btn-type-primary-danger.ant-btn-text[disabled],
.icon-btn-type-primary-danger.ant-btn-text[disabled]:focus,
.icon-btn-type-primary-danger.ant-btn-text[disabled]:active,
.icon-btn-type-primary-danger.ant-btn-text[disabled]:hover,
.icon-btn-type-primary-warning.ant-btn-text[disabled],
.icon-btn-type-primary-warning.ant-btn-text[disabled]:focus,
.icon-btn-type-primary-warning.ant-btn-text[disabled]:active,
.icon-btn-type-primary-warning.ant-btn-text[disabled]:hover {
  background-color: var(--disabled);
  border: none;
  color: var(--basic-100);
  svg {
    * {
      fill: var(--basic-100);
    }
  }
}

.icon-btn-type-secondary-primary.ant-btn-text[disabled],
.icon-btn-type-secondary-primary.ant-btn-text[disabled]:focus,
.icon-btn-type-secondary-primary.ant-btn-text[disabled]:active,
.icon-btn-type-secondary-primary.ant-btn-text[disabled]:hover,
.icon-btn-type-secondary-basic.ant-btn-text[disabled],
.icon-btn-type-secondary-basic.ant-btn-text[disabled]:focus,
.icon-btn-type-secondary-basic.ant-btn-text[disabled]:active,
.icon-btn-type-secondary-basic.ant-btn-text[disabled]:hover,
.icon-btn-type-secondary-danger.ant-btn-text[disabled],
.icon-btn-type-secondary-danger.ant-btn-text[disabled]:focus,
.icon-btn-type-secondary-danger.ant-btn-text[disabled]:active,
.icon-btn-type-secondary-danger.ant-btn-text[disabled]:hover,
.icon-btn-type-secondary-warning.ant-btn-text[disabled],
.icon-btn-type-secondary-warning.ant-btn-text[disabled]:focus,
.icon-btn-type-secondary-warning.ant-btn-text[disabled]:active,
.icon-btn-type-secondary-warning.ant-btn-text[disabled]:hover {
  //border-color: var(--disabled);
  background-color: transparent;
  border: solid 1px var(--disabled);
  color: var(--disabled);
  svg {
    * {
      fill: var(--disabled);
    }
  }
}

.icon-btn-type-tertiary-primary.ant-btn-text[disabled] .anticon,
.icon-btn-type-tertiary-primary.ant-btn-text[disabled]:focus .anticon,
.icon-btn-type-tertiary-primary.ant-btn-text[disabled]:active .anticon,
.icon-btn-type-tertiary-primary.ant-btn-text[disabled]:hover .anticon,
.icon-btn-type-tertiary-basic.ant-btn-text[disabled] .anticon,
.icon-btn-type-tertiary-basic.ant-btn-text[disabled]:focus .anticon,
.icon-btn-type-tertiary-basic.ant-btn-text[disabled]:active .anticon,
.icon-btn-type-tertiary-basic.ant-btn-text[disabled]:hover .anticon,
.icon-btn-type-tertiary-danger.ant-btn-text[disabled] .anticon,
.icon-btn-type-tertiary-danger.ant-btn-text[disabled]:focus .anticon,
.icon-btn-type-tertiary-danger.ant-btn-text[disabled]:active .anticon,
.icon-btn-type-tertiary-danger.ant-btn-text[disabled]:hover .anticon,
.icon-btn-type-tertiary-warning.ant-btn-text[disabled] .anticon,
.icon-btn-type-tertiary-warning.ant-btn-text[disabled]:focus .anticon,
.icon-btn-type-tertiary-warning.ant-btn-text[disabled]:active .anticon,
.icon-btn-type-tertiary-warning.ant-btn-text[disabled]:hover .anticon {
  background-color: transparent;
  border: none;
  color: var(--disabled);
  svg {
    * {
      fill: var(--disabled);
    }
  }
}